import { Card } from "react-bootstrap";
import CardReferralLink from "../components/CardReferralLink.js";
import { useLoaderData } from "react-router-dom";
import { ReferralData } from "../data/ReferralData.js";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";

export function CardIDLoader({ params }) {
  const id = params.id.replace(/[^a-zA-Z]+/gi, "").toLowerCase();

  return { id };
}

export default function Code() {
  const { id } = useLoaderData();
  const data = ReferralData();
  const card = data[id];
  if (!card) {
    throw new Response("", {
      status: 404,
      statusText: "Not Found",
    });
  }
  return AccordionCard(card);
}

function AccordionCard(cardData) {
  let itemContent = null;
  if (cardData.ShowCode) {
    itemContent = (
        <span className="d-block pb-1">
        Code: <strong>{cardData.ReferralCode}</strong>
      </span>
    );
  }
  return (
    <Fragment>
      <Helmet>
        <meta
          name="description"
          content={cardData.Company + ` Referral Code. ` + cardData.Company + ` Invite Code. Referred by a friend. UK referral codes and invite codes. Sharing the love for my favourite brands.`}
        />
        <title>{cardData.Company} Referral Code - Referred by a friend</title>
      </Helmet>
      <Card>
        <Card.Body>
          <Card.Title>{cardData.Company}</Card.Title>
          <Card.Subtitle><span className="d-block pb-3">{cardData.CompanyDescription}</span></Card.Subtitle>
          <Card.Text>
            <span className="d-block pb-1">
              You Get: <strong>{cardData.YouGet}</strong>
            </span>
            <span className="d-block pb-1">
              I Get: <strong>{cardData.IGet}</strong>
            </span>
            {itemContent}
          </Card.Text>
          <h1 className="h6">
            <CardReferralLink href={cardData.ReferralLink}>
              {cardData.Company} Referral Code
            </CardReferralLink>
          </h1>
        </Card.Body>
      </Card>
    </Fragment>
  );
}
