import { Card } from "react-bootstrap";

const CardReferralLink = ({ href, children }) => {
  return (
    <Card.Link href={href} target="_blank" rel="noreferrer">
      {children}
    </Card.Link>
  );
};

export default CardReferralLink;
