import { Accordion } from "react-bootstrap";
import ReferralCard from "./ReferralCard.js";
import ReferralAccordianItem from "./ReferralAccordianItem.js";
import { ReferralData, ReferralSections } from "../data/ReferralData.js";

const data = ReferralData();
const ReferralAccordian = () => {
  const sections = ReferralSections();
  const rows = [];
  for (let i = 0; i < sections.length; i++) {
    rows.push(AccordianSection(i, sections[i]));
  }
  return (
    <Accordion flush defaultActiveKey="0">
      {rows}
    </Accordion>
  );
};

function AccordianSection(index, section) {
  const cards = [];
  for (let i = 0; i < section.Referrals.length; i++) {
    const refName = section.Referrals[i];
    const card = data[refName];
    if (!card) {
      continue;
    }
    cards.push(AccordionCard(card));
  }
  return (
    <ReferralAccordianItem title={section.Title} eventKey={`` + index} key={section.Title}>
      {cards}
    </ReferralAccordianItem>
  );
}

function AccordionCard(cardData) {
  let itemContent = null;
  if (cardData.ShowCode) {
    itemContent = (
      <span className="d-block pb-1">
        Code: <strong>{cardData.ReferralCode}</strong>
      </span>
    );
  }
  return (
      <ReferralCard
          company={cardData.Company}
          description={cardData.CompanyDescription}
          referralURL={cardData.ReferralLink}
          key={cardData.Company}
      >
      <span className="d-block pb-1">
        You Get: <strong>{cardData.YouGet}</strong>
      </span>
        <span className="d-block pb-1">
        I Get: <strong>{cardData.IGet}</strong>
      </span>
        {itemContent}
      </ReferralCard>
  );
}

export default ReferralAccordian;
